/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import { List, ListItem, withStyles } from "@material-ui/core";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import footerStyle from "assets/jss/material-kit-react/components/footerStyle.jsx";

import Button from "components/CustomButtons/Button.jsx";
import { Link } from "gatsby"

function Footer({ ...props }) {
  const { classes, whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://www.dom-apotheke-buer.de/"
                className={classes.block}
                target="_blank"
              >
                Dom-Apotheke
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://www.buersche-falken-Apotheke.de"
                className={classes.block}
                target="_blank"
              >
                Buersche Falken-Apotheke
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                  href="/SchreinersApotheken/Datenschutz"
                  className={classes.block}
              >
                Datenschutz
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Button
                  onClick={() => klaro.show()}
                  color="transparent"
                  target="_blank"
                  className={classes.navLink}
              >
                Cookies
              </Button>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                  href="/SchreinersApotheken/Impressum"
                  className={classes.block}
              >
                Impressum
              </a>
            </ListItem>
          </List>
        </div>
        {/*<div className={classes.right}>

          &copy; {1900 + new Date().getYear()}, made in Buer with{" "}
          <Favorite className={classes.icon} /> by{" "}
          <a
            href="https://www.adunique.de"
            className={aClasses}
            target="_blank"
          >
            Adunique GmbH
          </a>{" "}
        </div>*/}
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
